import React, { useContext, useEffect, useState } from 'react';
import * as S from './ActiveSubscriptionSelectorV3.styles';
import axios from 'axios';
import FunnelContext from '../../context/FunnelContext';
import { Builder } from '@builder.io/react';
import { addDataLayer } from '../../utils/dataLayer';
import LoadingCover from '../LoadingCover/LoadingCover';
import { ProductPrice } from '../../utils/productPrice';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const getParamFromQuery = key => {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(key) || '';
};

const ActiveSubscriptionSelector = props => {
  const {
    children,
    expiredTokenRedirect = 'https://thepetlabco.com/pages/token-expired',
    redirectIfAuthFails = false,
    endpointApiSite = 'https://qa2-subscription.thepetlabco.uk',
    petlabCampaignTokenKey = 'petlab_campaign_token',
    klaviyoUserIdKey = 'utm_id',
    klaviyoCampaignIdKey = 'utm_campaign',
    variantExistsMessage,
    accountPortalUrl = `https://account.thepetlabco.com/dashboard`,
    successLayerId = 'success',
    failedLayerId = 'failed',
    subEngineProductId,
    filterSku = ''
  } = props;

  const [subscriptionList, setSubscriptionList] = useState(null);
  const [customerId, setCustomerId] = useState(0);
  const [token, setToken] = useState(null);
  const [activeSubscription, setActiveSubscription] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loadingCover, setLoadingCover] = useState(true);

  const {
    currentTubIndex,
    extraObjects,
    currentCategory,
    setExtraObjects,
    currentProduct,
    setCurrentLayer
  } = useContext(FunnelContext);

  const currentProductData = ProductPrice({
    type: `data`,
    currentCategory,
    currentProduct,
    tub: currentTubIndex,
    filterByTag: extraObjects?.filterProductItemsByTag || false
  });

  const cookieKey = `free_gift_addon_${subEngineProductId}`;

  useEffect(() => {
    if (loadingCover && cookies.get(cookieKey)) {
      setCurrentLayer(failedLayerId);
      setLoadingCover(false);
    }
  }, [cookieKey, loadingCover, setCurrentLayer, failedLayerId]);

  useEffect(() => {
    if (Builder.isEditing) return;

    if (!subscriptionList) {
      const petlabCampaignTokenValue = getParamFromQuery(
        petlabCampaignTokenKey
      );
      const klaviyoUserIdValue = getParamFromQuery(klaviyoUserIdKey);
      let klaviyoCampaignIdValue = getParamFromQuery(
        klaviyoCampaignIdKey
      ).match(/\((.*)\)/g);
      if (klaviyoCampaignIdValue) {
        klaviyoCampaignIdValue = klaviyoCampaignIdValue[0] || '';
        klaviyoCampaignIdValue = klaviyoCampaignIdValue
          .replace('(', '')
          .replace(')', '');
      }

      if (
        !petlabCampaignTokenValue ||
        petlabCampaignTokenValue === '' ||
        !klaviyoUserIdValue ||
        klaviyoUserIdValue === '' ||
        !klaviyoCampaignIdValue ||
        klaviyoCampaignIdValue === ''
      ) {
        window.location.href = expiredTokenRedirect;
        return;
      }

      try {
        axios
          .post(`${endpointApiSite}/api/campaign/auth`, {
            petlab_campaign_token: petlabCampaignTokenValue,
            klaviyo_user_id: klaviyoUserIdValue,
            klaviyo_campaign_id: klaviyoCampaignIdValue
          })
          .then(({ data }) => {
            const results = JSON?.parse(data);
            setToken(results['token']);
            setCustomerId(results['customer_id']);
            axios
              .get(`${endpointApiSite}/api/subscription/list`, {
                headers: {
                  Authorization: `Bearer ${results['token']}`
                }
              })
              .then(({ data: { data } }) => {
                const subscriptionsData = data
                  .filter(item => item.nextDelivery)
                  .map(item => {
                    return {
                      ...item,
                      nextDeliveryDate: new Date(item.nextDelivery)
                    };
                  })
                  .filter(item => item.nextDeliveryDate > new Date())
                  .filter(item => {
                    if (filterSku.length <= 0) return true;
                    return item.product.sku.includes(filterSku);
                  })
                  .sort((a, b) => a.nextDeliveryDate - b.nextDeliveryDate);
                return { subscriptionsData };
              })
              .then(({ subscriptionsData }) => {
                const [firstItem = null] = subscriptionsData;
                console.log('Active Subscription:', firstItem);
                if (!firstItem && !Builder.isEditing) {
                  console.log('Error: No Active Subscription');
                  addDataLayer('magic_link_login_failed_event');
                  window.location.href = expiredTokenRedirect;
                }
                setActiveSubscription(firstItem);
                setSubscriptionList(subscriptionsData);
                setExtraObjects({
                  ...extraObjects,
                  firstname: firstItem?.shippingAddress.firstName
                });

                addDataLayer('magic_link_page_view', {
                  sku: currentProductData.checkoutData.product_id,
                  sub_db_user_id: results['customer_id']
                });

                const isDisabled = firstItem.additionalProducts.find(
                  item => parseInt(item.id) === parseInt(subEngineProductId)
                );
                if (isDisabled) {
                  setCurrentLayer(failedLayerId);
                  cookies.set(cookieKey, 1);
                }

                setLoadingCover(false);
              });
          })
          .catch(error => {
            console.log(error);
            if (redirectIfAuthFails && !Builder.isEditing) {
              addDataLayer('magic_link_login_failed_event');
              window.location.href = expiredTokenRedirect;
            }
          });
      } catch (error) {
        console.log(error);
        if (redirectIfAuthFails && !Builder.isEditing) {
          addDataLayer('magic_link_login_failed_event');
          window.location.href = expiredTokenRedirect;
        }
      }
    }
  }, [
    subscriptionList,
    setSubscriptionList,
    petlabCampaignTokenKey,
    klaviyoUserIdKey,
    klaviyoCampaignIdKey,
    endpointApiSite,
    setToken,
    redirectIfAuthFails,
    expiredTokenRedirect,
    extraObjects,
    setExtraObjects,
    setLoadingCover,
    currentProductData,
    setCurrentLayer,
    failedLayerId,
    subEngineProductId,
    filterSku,
    cookieKey,
    setCustomerId
  ]);

  const submitOrder = () => {
    try {
      return axios
        .post(
          `${endpointApiSite}/api/subscription/${activeSubscription.id}/additional-product/add`,
          {
            productId: parseInt(subEngineProductId),
            quantity: currentProductData.data.quantity,
            discountCode: 'DISCOUNT_FREE_UNIT',
            applyDiscountType: 3
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(results => {
          addDataLayer('magic_link_succesful_cross_sell', {
            sku: currentProductData.checkoutData.product_id,
            sub_db_user_id: customerId,
            sub_id: activeSubscription?.id,
            value: currentProductData.checkoutData.discounted_price
          });
          cookies.set(cookieKey, 1);
          setCurrentLayer(successLayerId);
          setButtonLoading(false);
          return results;
        });
    } catch (err) {
      console.log(err);
      setCurrentLayer(failedLayerId);
      setButtonLoading(false);
    }
  };

  const Button = ({ children }) => {
    const isDisabled = activeSubscription.additionalProducts.find(
      item => parseInt(item.id) === parseInt(subEngineProductId)
    );

    const handleButtonClick = () => {
      if (Builder.isEditing) return;
      if (!activeSubscription) return;
      if (isDisabled) return;
      setButtonLoading(true);
      addDataLayer('magic_link_add_to_cart', {
        sku: currentProductData.checkoutData.product_id,
        sub_db_user_id: customerId,
        sub_id: activeSubscription?.id,
        value: currentProductData.checkoutData.discounted_price
      });
      submitOrder();
    };

    const _variantExistsMessage =
      variantExistsMessage ||
      `Good news, you already have this product in your subscription! 
      Please visit our <a href="${accountPortalUrl}" target="_blank">Account Portal</a> 
      if you would like to update the quantity of this product or to access even more bespoke offers.`;

    return (
      <>
        {children ? (
          <S.Button2
            className={isDisabled ? `disabled` : ``}
            onClick={handleButtonClick}
            buttonLoading={buttonLoading}
          >
            {children}
          </S.Button2>
        ) : (
          <S.Button
            className={isDisabled ? `disabled` : ``}
            onClick={handleButtonClick}
          >
            {buttonLoading ? (
              <img src="/images/three-dots.svg" alt="loading" />
            ) : currentCategory === 'subscriptions' ? (
              `Add to Subscription`
            ) : (
              ` Add to the next Delivery`
            )}
          </S.Button>
        )}
        {isDisabled && (
          <S.ExistsMessage
            isCentered={variantExistsMessage}
            dangerouslySetInnerHTML={{ __html: _variantExistsMessage }}
          />
        )}
      </>
    );
  };

  return (
    <>
      <S.Container>
        {!subscriptionList && (
          <S.Loading>
            <img src="/images/three-dots.svg" alt="loading" />
          </S.Loading>
        )}
        {subscriptionList && (
          <>
            {activeSubscription && (
              <>{children ? <Button>{children}</Button> : <Button />}</>
            )}
          </>
        )}
      </S.Container>
      {loadingCover && !Builder.isEditing && <LoadingCover />}
    </>
  );
};

export default ActiveSubscriptionSelector;
